body {
    font-family: proxima-nova,sans-serif;
    font-weight: 600 !important;
    font-style: normal;
    line-height: 1.3;
}

p, ul, li {
    font-family: proxima-nova,sans-serif;
    font-weight: 600 !important;
    font-style: normal;
    color: #004d80;
    line-height: 1.3;
}

h1, h2, h3, button, div {
    font-family: proxima-nova,sans-serif;
    font-weight: 800 !important;
    font-style: normal;
    color: #004d80;
}

h1, h2, h3, button, div {
    font-family: proxima-nova,sans-serif;
    font-weight: 800 !important;
    font-style: normal;
    color: #004d80;
}

.italic {
    font-style: italic;
}

a {
    color: #004d80 !important;
}

.loader {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.chicken-book {
    width: 100%;
    margin: 0;
}
/*
@media screen and (max-width:600px) {
    .chicken-book {
        width: 100%;
        margin: 0;
    }
}
*/



.App {
    text-align: center;
}

.qizu-logo {
    margin: 0 25%;
    width: 50%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.card-body {
    padding: 0.75rem !important;
}

.card-text {
    font-size: small;
}




.game-max-width {
    max-width: 40em;
    margin: 0 auto;
}


.text-logo {
    font-size: 4em;
}


.coe-orange-color {
    color: #f27200;
}

.coe-orange-background-color {
    background-color: #f27200 !important;
    color: #fff !important;
}

.coe-blue-color {
    color: #004d80;
}

.coe-blue-background-color {
    background-color: #004d80 !important;
    color: #fff !important;
}

.coe-grey-color {
    color: #808080;
}

.coe-grey-background-color {
    background-color: #808080 !important;
    color: #fff !important;
}

.coe-maroon-color {
    color: #800000
}

.coe-maroon-background-color {
    background-color: #800000 !important;
    color: #fff !important;
}

.coe-green-color {
    color: #28a745;
}

.coe-green-background-color {
    background-color: #28a745 !important;
    color: #fff !important;
}



.score {
    display: flex;
    flex-flow: row;
    margin: 0 0 0.5em;
}

    .score .scoreTitle {
        width: 30px;
    }

    .score .scoreBar {
        width: 100%;
    }

        .score .scoreBar .bar {
            background-color: #004d80;
            height: 100%;
        }


        .score .scoreBarSelected .bar {
            background-color: #f27200;
            height: 100%;
        }
        


.score .scorePercentage {
    width: 50px;
    margin-left: 1%;
    text-align: right;
}


.shake{
    animation: shake 1s infinite;
}
@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}




/* BOOK COVERS */

.book-cover {
    width: 12.5%;
}